/* Small screen */
.formField {
    font-size: 15px !important; /* Don't make less than 16px to avoid auto-zoom on iPhone */
    color: rgb(33, 33, 33) !important;
    margin-top: 0 !important;
    margin-bottom: 15px !important;
    width: 100%;
    font-weight: 400;
    /*height: 3em;*/
}

.formField label {
    /*color: rgb(77, 77, 77) !important;*/
    font-size: 15px;
    font-weight: 400;
    font-family: Montserrat, sans-serif !important;
}

.formField fieldset {
    border-radius: 4px !important;
    /*border-color: rgba(86, 128, 237, .6);*/
    /*border-color: rgba(66, 66, 66, .2);*/
    /*border-color: transparent;*/
}

.formField input {
    font-size: 18px;
    height: 1rem;
    font-weight: 400;
    font-family: Montserrat, sans-serif;
    color: rgb(33, 33, 33);
    background-color: #fff;
    border-radius: 9px !important;
}

.inputSideBar input{
    height: 4rem !important;
}

form label {
    color: #666;
    font-weight: 600;
    font-size: 15px !important;
}

/* Big screen */
@media (min-width: 576px) {
    .formField {
        font-size: 18px !important;
        /* margin-bottom: 1.2em; */
    }

    .formField label {
        font-size: 18px;
    }

    form label {
        font-size: 17px !important;
    }
}
