.installments-table-wrapper {
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 100%;
    font-family: 'Montserrat' !important;
}

.installments-table-wrapper .MuiPaper-root {
	min-width: 240px;
	flex: 1;
}

.installments-table-wrapper th {
	font-weight: bold;
}

.installments-table-wrapper .MuiTableCell-root {
	padding: 8px 16px;
}

.installments-modal-text-wrapper {
	font-family: 'Montserrat' !important;
}

.installments-modal-text-wrapper .radioGroup.MuiFormGroup-root {
	margin-left: 0px;
	justify-content: flex-start;
}