h1 {
    font-size: 40px;
    text-align: center;
    white-space: nowrap;
}

.row {
    margin-top: 30px;
    margin-bottom: 0;
}

.result {
    font-size: 20px;
    line-height: 1.5;
}

.stepSubtitle p {
    font-size: 1.5em;
    font-weight: 300;
}

.frameBorder {
    border-radius: 15px;
    padding: 20px;
    color: #2e305f;
    margin-top: 8vh;
    border-radius: 25px;
    background-color: white;
    box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
}

.headerTitle {
    font-size: 25px;
}

.moneyPar {
    text-align: center;
    font-size: 1vw;
    font-weight: 450;
    display: block;
    min-height: 44px !important;
}

.offreButton {
    height: 6vh;
    cursor: pointer;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 1.05em;
    text-align: center;
    border-radius: 15px;
    border: 2px solid #5d7ae4;
    background-color: white;
    color: #5d7ae4;
}

.offreButton:hover {
    height: 6vh;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 15px;
    font-size: 1.05em;
    text-align: center;
    border: 2px solid white;
    background-color: #5d7ae4;
    color: white;
}
.offreButton * {
    vertical-align: middle;
}

.moneyParMobile {
    text-align: center;
    font-size: 20px;
    font-weight: 450;
}

.registerSection p {
    font-size: 18px;
    text-align: center;
}

.acceptTerms {
    line-height: 1.5;
    margin-top: .5em;
}

.odometerWrapper {
    font-size: 1.5vw;
    margin: 10px;
}

@media only screen and (max-width: 1300px) {
    .odometerWrapper {
        font-size: 1.7rem;
        margin: 10px;
    }
}

@media only screen and (max-width: 400px) {
    .odometerWrapper {
        font-size: 22px;
        margin: 10px;
    }
}

@media only screen and (min-width: 500px) {
    .totalSavings h4 {
        font-size: 25px;
        margin-block-start: 0;
        margin-block-end: 0;
    }

    /*.offerRow {*/
    /*    margin-top: 6rem;*/
    /*}*/
}
.offerRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.imgLogo {
    height: 100px;
    margin-right: 20px;
}
