.sideEar.mobile {
    top: 300px;
    right: 0;
    width: 400px;
    min-height: 150px;
    margin-right: -370px;
    color: #ffffff;
    position: fixed;
    cursor: pointer;
    z-index: 1000;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    transition: margin-right .4s ease-out;
}

@media screen and (max-width: 380px) {
    .sideEar.mobile {
        width: 350px;
        margin-right: -320px;
    }

    div.callBackForm {
        width: 350px;
        margin-right: -360px;
    }

    .sideEar.mobile .sideEarContent .method.chat p {
        font-size: 15px;
    }
}

@media screen and (min-width: 320px) and (max-width: 355px) {
    .sideEar.mobile {
        width: 320px;
        margin-right: -290px;
    }

    div.callBackForm {
        width: 350px;
        margin-right: -360px;
    }
}

@media screen and (min-width: 376px) {
    .sideEar.mobile .sideEarContent .method p {
        letter-spacing: 1px;
    }
}

@media screen and (max-height: 460px) {
    .sideEar.mobile .sideEar.mobile {
        top: 140px;
    }

    div.callBackForm {
        top: 30px;
    }

}

.sideEar.mobile.active {
    margin-right: 0;
}

.sideEar.mobile:hover {
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .4);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .4);
}

.sideEar.mobile .earText {
    border-radius: 0 0 3px 3px;
    background: #5372e4;
    line-height: 30px;
    width: 150px;
    text-align: center;
    transform: rotate(-90deg);
    margin: 60px 0 0 -60px;
    border-top: 1px solid rgba(250, 250, 250, 0.3);
}

.sideEar.mobile .sideEarContent {
    padding: 0 20px;
    margin-left: 30px;
    display: flex;
    flex-direction: row;
    background: #ffffff;
    height: 150px;
    margin-top: -91px;
    justify-content: center;
    vertical-align: middle;
    box-sizing: border-box;
    background: hsla(0, 0%, 100%, .4);
    box-shadow: 0 10px 24px rgba(8, 15, 26, .16);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.sideEar.mobile .sideEarContent .method {
    color: #5372e4;
    margin: 0 auto;
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
}

.sideEar.mobile .sideEarContent .method p {
    margin-bottom: 0;
}

.sideEar .svgWrap {
    width: 26px;
    height: 26px;
    padding: 16px 16px 14px 16px;
    border-radius: 50px;
    /*background: #3451b9;*/
    /*margin: 40px auto 0 auto;*/
    margin: 0 auto;
    display: block;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}

.sideEar .svgWrap:hover {
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .4);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .4);
}

.sideEar .chat .svgWrap {
    color: rgb(0, 125, 252);
    background: linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255));
}

.sideEar .chatIcon {
    width: 24px;
    height: 24px;
}

.sideEar .callBack .svgWrap {
    background: #5372e4;
}

.sideEar .call .svgWrap {
    background: #4CD964;
}

.callBackForm {
    width: 450px;
    max-width: 100%;
}

.sideEar .call i,
.sideEar .callBack i {
    color: #ffffff;
}

.sideEar .callBack i {
    font-size: 26px;
}

.sideEar .call i {
    font-size: 23px;
}

.callBackForm {
    box-sizing: border-box;
    position: fixed;
    top: 200px;
    right: 0;
    width: 400px;
    height: 95px;
    background: white;
    background: hsla(0, 0%, 100%, .4);
    /*box-shadow: 0 10px 24px rgba(8, 15, 26, .16);*/
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .4);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .4);

    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 0 0 3px 3px;
    transition: margin-right .4s ease-out;
    margin-right: -410px;
    z-index: 1000;
}

.callBackForm.active {
    margin-right: 0;
}

button.submitCallback {
    padding: 0;
    background: #5372e4;
    color: #ffffff;
    font-size: 15px;
    margin: 42px -2% 1.5%;
    height: 50px;
    min-width: 30%;
    display: flex;
}

button.submitCallback.green {
    background: #4CD964;
}

.submitCallInput {
    height: 30px;
    border: 1px solid rgba(118, 118, 118, 0.3);
    font-family: Montserrat, sans-serif;
    border-radius: 3px;
    /*display: inline-block;*/
    /*margin: 30px 2.5% 0 2.5%;*/
    outline: none;
    /*margin: 20px 1.5% 0 1.5%!important;*/
    margin: 5px 1.5% 0 1.5% !important;
    display: inline-block !important;
}

.submitCallInput .MuiInput-formControl {
    margin-top: 0 !important;
}

input.submitCallInput:placeholder {
    margin-left: 5px;
}

input.submitCallInput:active,
input.submitCallInput:focus {
    border: 1px solid #5372e4;
}

/*DESKTOP*/
.callBackForm.desktop {
    top: auto;
    bottom: 155px;
    right: 20px;
    margin-right: -440px;
    z-index: 9999999989;
}

.callBackForm.desktop.active {
    margin: 0;
}

.sideEar.desktop {
    /*bottom: 30px;*/
    bottom: 50px;
    right: 20px;
    min-width: 200px;
    height: 75px;
    /*margin-right: -370px;*/
    color: #ffffff;
    position: fixed;
    cursor: pointer;
    /*background: #3451b9;*/
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    /*transition: margin-right .4s ease-out;*/
    border-radius: 1000px;
    transition: width .6s ease-out;
    background: hsla(0, 0%, 100%, .4);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 9999999989;
    display: none;
}

.sideEar.desktop .sideEarContent {
    width: 0;
    height: 0;
    margin: 0;
    box-sizing: border-box;
    /*overflow: hidden;*/
    float: left;
}

.sideEar.desktop .earText {
    text-align: center;
    height: 75px;
    width: 200px;
    line-height: 75px;
    margin: 0;
    float: left;
    border-radius: 1000px;
    background: #4cd964;
    font-size: 18px;
    font-weight: 500;
}

.sideEar.desktop .earText * {
    vertical-align: middle;
}

.sideEar.desktop .earText .question {
    height: 28px;
    font-size: 26px;
    margin-right: 7px;
}

.sideEar.mobile .earText .question {
    margin-right: 7px;
}

.sideEar.desktop .sideEarContent {
    display: flex;
    width: 280px;
    flex-direction: row;
    height: 75px;
    justify-content: center;
    vertical-align: middle;
    background: transparent;
    box-sizing: border-box;
    opacity: 0;
    margin-right: -280px;
    transition: margin .6s ease-out, opacity .4s ease-out;
}

.sideEar.desktop.active .sideEarContent {
    margin-right: 0;
    padding: 0 20px;
    opacity: 1;
}

.sideEar.desktop .sideEarContent .method {
    color: #5372e4;
    margin: 0 auto;
    flex: 1;
    text-align: center;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
}

.earPhone {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 20px;
    background-color: #5372e4;
    color: white !important;
    text-decoration: none;
}


.conseil_btn {
    position: fixed;
    right: 10px;
    bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 60px;
    height: 60px;
    clear: right;
    justify-content: center;
    align-items: center;
    z-index: 1000000;
    border-radius: 50px;
    /*background-color: #5372e4;*/
    /*background: linear-gradient(180deg, #5372e4, rgb(63, 165, 238));*/
    box-shadow: 0 3px 10px 0 rgb(30 32 83 / 29%);
}

.conseil_btn h2 {
    font-weight: 600;
    color: white;
    line-height: 100%;
}

.conseilButton {
    background-color: white;
    font-weight: 600;
    width: 215px;
    border-radius: 10px;
    padding: 10px;
}

.conseilFrame {
    border-radius: 10px;
    /*padding: 12px;*/
    margin: 5px 15px 10px 15px;
    /*border: 1px solid rgb(0 0 0 / 6%);*/
    /*box-shadow: 0 1px 2px rgb(0 0 0 / 20%);*/
    /*-webkit-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);*/
}

.conseilFrame p,
.conseilFrame svg {
    color: #fff;
}

.conseilFrame:last-child {
    margin-bottom: 30px;
}

.sideEar.desktop .sideEarContent .method p {
    margin-bottom: 0;
}

.sideEar.desktop .method p {
    /*display: none;*/
    /*margin-top: -80px;*/
    margin-top: 0;
    opacity: 0;

    position: absolute;
    width: 90%;
    background: white;
    padding: 10px 0;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    border-radius: 10px;
    font-size: 15px;
    transition: margin .2s ease-out, opacity .2s ease-out;
}

.sideEar.desktop .method p:after {
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    display: inline-block;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #ffffff;
    bottom: 0;
    margin-bottom: -8px;
    left: 50%;
    margin-left: -4px;
}

.sideEar.desktop .method:hover p {
    margin-top: -80px;
    opacity: 1;
}

.contactAction {
    /*color: #262247;*/
    width: 215px;
    margin: 5px auto 15px auto;
    color: #fff;
    text-align: left;
}

.contactAction svg {
    width: 21px;
    vertical-align: middle;
}

/*.sideEar.desktop.active .method p {*/
/*    margin-top: -80px;*/
/*    opacity: 1;*/
/*}*/
