h1 {
    font-size: 40px;
    text-align: center;
    white-space: nowrap;
}

.row {
    margin-top: 30px;
    margin-bottom: 0;
}

.result {
    font-size: 17px;
    line-height: 1.6;
    margin-top: 2rem;
}

.stepSubtitle h4 {
    font-size: 1.4em;
    font-weight: 300;
}

@media only screen and (max-width: 2200px) {
    .title_dynamic {
      height: 40px;
    }
  }

.headerTitle {
    font-size: 30px;
}

.registerSection p {
    font-size: 18px;
    text-align: center;
}

.acceptTerms {
    line-height: 1.5;
    margin-top: .5em;
    font-size: 10px !important;
    text-align: justify;
}
.acceptTerms a {
    font-weight: bold;
}

.acceptCheckbox {
    float: left;
}

.odometerWrapper {
    font-size: 42px;
    margin: 10px;
}

.odometerWrapperSmall {
    font-size: 30px;
    margin-top: 0;
}

.perMonth {
    margin-top: 0;
    /*padding-left: 15px;*/
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.compareText {
    text-align: center;
    line-height: 1.5;
    margin-top: 35px;
}

.compareText button {
    font-family: Montserrat, Roboto, sans-serif;
    font-size: 16px;
    overflow: hidden;
    height: auto;
    padding: 7px 15px;
    margin-top: 10px;
    line-height: 24px;
    font-weight: 400;
    color: #fff;
    text-transform: none;
    margin-bottom: 15px;
    border-radius: 0;
    display: inline-block;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}

.compareText button:hover {
    background: #5372e4;
    text-decoration: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .4);
}

.verticalCenter {
    margin-top: 5vh;
}

.title {
    display: block;
    text-align: center;
    margin-bottom: 10px;
    padding-top: 40px;
}
