button.formButton,
label.formButton {
    font-family: Montserrat, Roboto, sans-serif;
    font-size: 1.05em;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    display: flex;
    height: auto;
    padding: 10px 0;
    line-height: 24px;
    font-weight: 550;

    /*Variant 1*/
    /*background: transparent;*/
    /*border: 2px solid #3451b9;*/
    /*color: #3451b9;*/

    /*Variant 2*/
    color: #fff;

    text-transform: none;
    border-radius: 5px;

    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}

.formButton.buttonSideBar {
    background-color: white;
    color: #627acc;
    height: 5vh;
}

.formButton.buttonSideBar:hover {
    background-color: #5372e4;
    color: white;
    height: 5vh;
}

button.whiteButton {
    background-color: #fff;
    color: #5372e4;
    border: 1px solid #5372e4;
}

button.fadingButton {
    opacity: 0.8;
    transition: opacity .3s ease-in-out;
}

button.fadingButton:hover {
    opacity: 1;
}

label.formButton.attachButton {
    font-size: 16px;
    line-height: 100%;
}

@media only screen and (max-width: 768px) {
    button.formButton, label.formButton {
        width: 100%;
    }

    label.formButton.attachButton {
        margin: 10px 0;
    }
}

button.formButton:hover,
label.formButton:hover {
    /*-webkit-box-shadow: 0 3px 10px rgba(112, 72, 232, .5);*/
    /*box-shadow: 0 3px 10px rgba(112, 72, 232, .5);*/
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .5);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .5);
}

label.formButton {
    cursor: pointer;
    text-align: center;
    display: inline-block;
}

button.formButton:hover,
label.formButton:hover {
    background: #5372e4;
    color: #ffffff;
}

button.formButton div span,
label.formButton div span {
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

button.formButton:disabled,
label.formButton:disabled {
    opacity: 0.5;
}

button.smallButton {
    font-size: 14px;
    /* height: 36px; */
    overflow: hidden;
    line-height: 18px;
    padding: 10px 0;
}

.formButton {
    font-size: 19px !important;
}

.buttons-wrapper {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.buttons-wrapper .formButton{
    margin: 10px !important;
    padding: 10px 20px;
}
/* Big screen */
@media only screen and (min-width: 576px) {
    button.formButton,
    label.formButton {
        font-size: 16px;
        line-height: 32px;
        padding: 10px 0;
    }

    button.smallButton {
        font-size: 16px;
        line-height: 24px;
    }
}

button.formButton {
    margin: 0 auto 15px auto;
    border-radius: 10px;
}

button.formButton.backButton {
    text-align: center;
    border: 2px solid #edf1f8;
    border-radius: 10px;
    background-color: #fff;
    box-shadow:0 6px 16px 0 rgba(0, 0, 0, 0.16);
    font-family: Montserrat, sans-serif;
    color: #819ab4;
    font-size: 20px;
    font-weight: 600;
}

button.formButton.socialButton {
    font-weight: 300;
    border-radius: 3px;
    width: 100%;
    padding: 8px;
    font-size: 16px;
}

button.formButton.socialButton:disabled {
    color: #fff;
}

button.formButton.socialButton.facebookButton {
    background-color: #3b5998;
}

button.formButton.socialButton.linkedInButton {
    background-color: #3395c4;
}

button.formButton.socialButton.emailButton {
    background-color: #555;
}

button.formButton.emailButton,
button.formButton.facebookButton,
.kep-login-facebook.metro{
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.emailButton {
    font-weight: normal !important;
}
