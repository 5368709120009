button.formButton {
    font-family: Montserrat, Roboto, sans-serif;
    font-size: 16px;
    overflow: hidden;
    height: auto;
    padding: 10px 0;
    line-height: 24px;
    font-weight: 400;
    background: #5372e4;
    color: #fff;
    text-transform: none;
    margin-bottom: 15px;
    border-radius: 4px;
}

button.formButton div span {
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

button.formButton:disabled {
    opacity: 0.5;
    color: #ffffff;
    cursor: not-allowed;
}

button.smallButton {
    font-size: 14px;
    /* height: 36px; */
    overflow: hidden;
    line-height: 18px;
    padding: 10px 0;
}

/* Big screen */
@media only screen and (min-width: 576px) {
    button.formButton {
        font-size: 16px;
        /* height: 64px; */
        line-height: 32px;
        padding: 10px 0;
        min-width: 80%;
    }

    button.smallButton {
        font-size: 16px;
        /* height: 42px; */
        line-height: 24px;
    }
}

button.formButton.socialButton {
    font-weight: 300;
    border-radius: 3px;
    width: 100%;
    padding: 8px;
    font-size: 16px;
}

button.formButton.socialButton:disabled {
    color: #fff;
}

button.formButton.socialButton.facebookButton {
    background-color: #3b5998;
}

button.formButton.socialButton.linkedInButton {
    background-color: #3395c4;
}

button.formButton.socialButton.emailButton {
    background-color: #555;
}

.backButton {
    background-color: red;
}
