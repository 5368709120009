@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(./../../fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 500;
    src: local('Poppins'), url(./../../fonts/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Black';
    src: local('Poppins'), url(./../../fonts/Poppins-Black.ttf) format('truetype');
}
.rdp-day,.rdp-head_cell,.rdp-caption_dropdowns {
    color: #666666 !important;
}
@font-face {
    font-family: 'Poppins-Medium';
    src: local('Poppins'), url(./../../fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Light';
    src: local('Poppins'), url(./../../fonts/Poppins-Light.ttf) format('truetype');
}

@font-face {
    font-weight: 700;
    font-family: 'Poppins';
    src: local('Poppins'), url(./../../fonts/Poppins-Bold.ttf) format('truetype');
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-size: 16px;
    font-family: Poppins, sans-serif !important;
    color: #666666;
    background: #fff;
    overflow-x: hidden;
    background-size: cover;
    background-attachment: fixed;
}
.additionalError .MuiInputBase-formControl {
    border: 1px solid red !important;
}
body.prevent-scrolling, body.quick-pricing-prevent {
    overflow: hidden;
}

.large-header .headerTitle {
    font-size: 26px;
}

.inline-block {
    display: inline-block;
}

.capitalize {
    text-transform: capitalize;
}

#g-recaptcha {
    transform: scale(0.8) !important;
    transform-origin: 0 0 !important;
}

a {
    color: #5372e4;
    cursor: pointer;
}

.purple {
    color: #5372e4 !important;
}

.white {
    color: #ffffff;
}

.hardWhite {
    color: #fff !important;
}

.lightBlue {
    color: #2daddd;
}

.capital {
    text-transform: capitalize;
}

button.formButton.lightBlueBg,
button.formButton.lightBlueBg:hover {
    background-color: #2daddd;
}

.successMessage, .errorMessage {
    font-size: 18px;
    text-align: center;
}

.successMessage {
    color: green;
}

.errorMessage {
    color: #e3332e;
}

.verticalCenter {
    height: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mainContainer {
    width: 100vw;
    height: 100vh;
}

.mainRow {
    margin: auto 0 !important;
    flex-flow: row wrap;
}

@media only screen and (min-width: 1300px) {
    .mainContainer {
        /*max-width: 60vw;*/
    }
}

@media only screen and (min-width: 600px) {
    .mainContainer {
        /*max-width: 90vw;*/
    }
}

.MuiFormHelperText-root.Mui-error:after {
    content: "!";
}

.MuiFormHelperText-root.Mui-error {
    color: #ffcccb !important;
    font-weight: 700;
}

.mainContainer .content {
    max-width: 100%;
    margin-left: 300px;
    min-height: 85vh;
    /*width: 100%;*/
    /*background-color: #f8f8f8;*/
    /*background-color: #f8f8f8;*/
    /*background-color: rgb(248,248,250);*/
    background: rgb(245, 245, 245);
    /*background-color: #fff;*/
    /*padding-top: 100px;*/
    /*padding-left: 60px !important;*/
    /*padding-right: 60px !important;*/
    display: flex;
    flex: 1;
    align-items: flex-start;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    /* -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2); */
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, .2); */
    overflow-x: hidden;
}

.mainContainer .sidebar {
    /*display: flex;*/
    width: 320px;
    padding: 0 !important;
    /*min-height: 100vh!important;*/
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #fff;
    /* -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .4);
     box-shadow: 0 2px 10px rgba(0, 0, 0, 0);*/
    z-index: 999;
    position: fixed;
    /*#1*/
    /*background-image: linear-gradient(*/
    /*        -90deg, #5372e4, #3c58bb);*/
    /*#2*/
    /*background-color: #3451b9;*/
}

div.sidebarStepper, div.personalSideBar {
    /*#1*/

    /*#2*/
    color: white;
}

/*.logoContainer {*/
/*    background-color: #001242;*/
/*    color: white;*/
/*}*/
svg.MuiStepIcon-root.MuiStepIcon-active,
svg.MuiStepIcon-root.MuiStepIcon-completed {
    color: #4cd964 !important;
}

svg.MuiStepIcon-root.MuiStepIcon-active .MuiStepIcon-text {
    fill: #000000;
}

svg.MuiStepIcon-root {
    color: #5380ed;
}

div.sidebarStepper .MuiStepLabel-label {
    color: #ffffff;
}

/*.accountContainer button.MuiButton-root.loginLogoutButton {*/
/*    background: #001242;*/
/*}*/
@media screen and (max-width: 1024px) {
    div.mainContainer .sidebar {
        /*max-width: 95vw;*/
        display: none;
    }

    div.mainContainer .content {
        margin-left: 0;
    }

    div.mainContainer .navBarMobile {
        display: block;
    }

    .accountContainer {
        text-align: right;
    }

    .PersonalSpace div#header {
        display: block;
        background-color: #292F44;
    }

    div.triangle {
        margin-left: 3vw;
        margin-top: 6vh;
    }

    .content .triangle {
        display: none;
    }

    .accountContainer button.MuiButton-root.loginLogoutButton {
        margin-top: 10px;
        background: #5380ed;
    }
}

/*offer page*/
.premiumPricing {
    text-align: center;
    font-weight: 700;
    font-size: 51px;
    letter-spacing: -2.11px;
    color: #88BAC7;
    opacity: 1;
}

.globalPricing {
    text-align: center;
    font-weight: 900;
    font-size: 59px;
    letter-spacing: -2.59px;
    color: #F6B60D;
    opacity: 1;
}

.traitImage {
    width: 350px;
    position: relative;
    top: -183px;
    right: 40px;
    margin-bottom: -350px;
}

.offerBenefits {
    font-size: 23px;
}

/*end offer page*/

.MuiSlider-mark {
    height: 6px !important;
}

span.MuiSlider-root .MuiSlider-markActive {
    background-color: #F6B60D !important;
}
.orangeBg {
    background-color: #F6B60D!important;
}
.orange {
    color: #F6B60D!important;
}
.roundedButton {
    background-color: yellow;
}

.mainContainer .contentWrapper {
    min-width: 80%;
    padding-bottom: 80px;
    margin: 0 auto;
    transition: flex 0.3s ease-in-out;
}

.centeredText {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
}

.primary {
    color: #5380ed;
}

.no-padding {
    padding: 0;
}

.no-margin {
    margin: 0;
}

.no-margin-top {
    margin-top: 0;
}

.no-margin-bottom {
    margin-bottom: 0;
}

@media screen and (max-width: 700px) {
    .centeredText {
        text-align: center;
        font-size: 14px;
    }
}

.centeredAlign {
    align-items: center !important;
}

.separator {
    border-color: rgba(86, 128, 237, 0.8);
    margin: 20px 0 50px 0;
}

.separatorHeading {
    font-size: 24px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    /*border-bottom: 1px solid rgba(50, 50, 50, 0.2);*/
}

.pretSeparator {
    margin: 18px auto;
    width: 500px;
    max-width: 100%;
}

.underlined {
    border-bottom: 1px solid rgba(50, 50, 50, 0.2);
}

.normal {
    font-weight: normal;
}

.firstRow, .paddingTop {
    padding-top: 2rem;
}

.MuiMenuItem-root {
    font-family: Montserrat, sans-serif !important;
    font-size: 16px !important;
    display: block !important;
    white-space: normal !important;
}

.MuiInputBase-input {
    font-family: Montserrat, sans-serif !important;

    padding: 16.5px 14px !important;
    font-size: 16px !important;
}

.MuiInputBase-input.MuiSelect-select {
    padding: 15px 14px !important;
    border-radius: 9px;
}

.MuiInputLabel-outlined:not(.MuiInputLabel-shrink) {
    transform: translate(14px, 17px) scale(1) !important;
}


.MuiTypography-body1 {
    font-size: 0.8rem;
}

.MuiInputBase-input.Mui-disabled {
    cursor: not-allowed !important;
    color: rgba(0, 0, 0, 0.38);
}

.center_radioButtons {
    text-align: center;
}

.radioGroup.MuiFormGroup-root {
    margin-top: 10px;
    margin-bottom: 0;
    margin-left: 70px;
    width: 100%;
    max-width: 800px !important;
}

.radioGroup.alignLeft.MuiFormGroup-root {
    justify-content: left;
    margin-left: 0;
}

.radioButton .MuiRadio-colorSecondary.Mui-checked {
    color: #75ACBA;
}

.radioButton .MuiRadio-colorSecondary.Mui-checked:hover {
    background-color: rgba(86, 128, 237, 0.04);
}

.radioButton .MuiRadio-colorSecondary:hover {
    background-color: rgba(86, 128, 237, 0.04);
}

.radioInput {
    display: flex;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

div.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0;
}

.backImg {
    cursor: pointer;
}

@media only screen and (min-width: 500px) {
    .radioInput {
        /*center on desktop, align left on mobile*/
        justify-content: center;
    }
}

.MuiFormControl-root .MuiTextField-root label {
    font-size: 19px !important;
}

span.MuiFormControlLabel-label,
p.MuiFormHelperText-root,
div.MuiFormControl-root label.MuiInputLabel-root.MuiInputLabel-formControl {
    font-family: Montserrat, sans-serif;
}

label.MuiInputLabel-root + .MuiInput-formControl {
    font-size: 18px !important;
    /*border-radius: 4px;*/
}

.inputLabel {
    color: #666666 !important;
    font-size: 15px !important;
    font-weight: 500;
}

div:not(.blueLabel).MuiFormControl-root label.MuiInputLabel-root.MuiInputLabel-formControl {
    color: #666666 !important;
    padding: 0 15px;
    width: 100%;
    position: relative;
    height: 22px;
    line-height: 1.1;
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
    font-size: 17px !important;
    font-weight: 500;
}

.blueLabel div.MuiFormControl-root label.MuiInputLabel-root.MuiInputLabel-formControl, .blueLabel .MuiFormLabel-root {
    color: #5372e4 !important;
}

div.MuiFormControl-root.social_phone label.MuiInputLabel-root.MuiInputLabel-formControl {
    font-size: 25px !important;
}

p.MuiFormHelperText-root {
    color: #000;
    margin-left: 13px;
    margin-top: 5px;
    font-size: 12px;
    line-height: 1;
}

.MuiTextField-root .MuiInputBase-root.MuiInput-root,
.MuiFormControl-root .MuiInputBase-root.MuiInput-root {
    background: #fff;
    border-radius: 9px;
}


.MuiTextField-root .MuiInputBase-root.MuiInput-root:after,
.MuiFormControl-root .MuiInputBase-root.MuiInput-root:after {
    border-radius: 4px;
    /*border-bottom: 3px solid #5380ed;*/
    height: 100%;
}

::-webkit-input-placeholder {
    opacity: 0.7 !important
}

::placeholder {
    opacity: 0.7 !important;
}

/*.MuiTextField-root .MuiInputBase-root.MuiInput-root:before {*/
/*    border: 2px solid rgba(0, 0, 0, 0.23)!important;*/
/*}*/
.MuiInputBase-adornedEnd span:not(.MuiIconButton-label) {
    margin-right: 15px;
}

/**/

.block {
    /*background: #ffff;*/
    /*padding: 30px 20px;*/
    /*margin: 5px 0;*/
    /*-webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);*/
    /*box-shadow: 0 2px 10px rgba(0, 0, 0, .2);*/
    /*border-radius: 4px;*/
}

.tip {
    text-decoration: underline;
}

.hint {
    /*margin-top: 12px;*/
    display: inline-block;
}

.bold {
    font-weight: bold;
}

.question-icon {
    font-size: 15px !important;
}

.question-icon.reversed {
    color: #ffffff;
    background: #5372e4;
}

.answer {
    font-weight: bold;
    padding-left: 15px;
}

a {
    color: #5372e4;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

@keyframes slideRight {
    /* style rules */
    from {
        opacity: 0;
        margin-right: -150px;
    }
    to {
        opacity: 1;
        margin-right: 0;
    }
}

/**
    Odometer CSS START
 */
.odometer.odometer-auto-theme, .odometer.odometer-theme-default {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
}

.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-default .odometer-digit {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
    text-align: left;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
    display: block;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
    display: block;
    -webkit-backface-visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-default .odometer-digit .odometer-value {
    display: block;
    -webkit-transform: translateZ(0);
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-default .odometer-digit .odometer-value.odometer-last-value {
    position: absolute;
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    -ms-transition: -ms-transform 2s;
    -o-transition: -o-transform 2s;
    transition: transform 2s;
}

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-down .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    -ms-transition: -ms-transform 2s;
    -o-transition: -o-transform 2s;
    transition: transform 2s;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}

.odometer.odometer-auto-theme, .odometer.odometer-theme-default {
    /* font-family: "Helvetica Neue", sans-serif; */
    line-height: 1.1em;
}

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-auto-theme .odometer-value, .odometer.odometer-theme-default .odometer-value {
    text-align: center;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-default .odometer-digit .odometer-value.odometer-last-value {
    width: 100%;
}

.odometer.odometer-auto-theme .odometer-inside .odometer-digit:last-child {
    display: none;
}

/**
    Odometer CSS END
 */


/**
    After for one or the other
 */

.orOther {
    position: absolute;
    right: 0;
    margin-top: 50px;
    text-transform: lowercase;
}

@media screen and (max-width: 768px) {
    .orOther:after {
    }
}

.step2-tooltip {
    border-radius: 21px;
    text-align: center;
}

.step2-tooltip:after {
    border-top-color: #5372e4 !important;
}

@media only screen and (max-width: 500px) {
    .step2-tooltip {
        left: 0 !important;
    }
}

div.progression {
    margin: 0 auto;
    padding-top: 15px;
    text-align: center;
}

div.progression span {
    border-radius: 100%;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    text-align: center;
    line-height: 20px;
    font-weight: 600;
}

div.progression span.selected {
    border: 3px solid #5372e4;
    color: #5372e4;
}

div.progression span:not(.selected) {
    border: 3px solid #adb5bd;
    color: #adb5bd;
}

.stepper {
    max-width: 300px;
    margin: 0 auto;
}

.sidebarStepper {
    margin-top: 50px;
}

.sidebarStepper .MuiStepLabel-label {
    font-size: 16px;
    font-family: Montserrat, sans-serif !important;
    line-height: normal;
    letter-spacing: normal;
}

.sidebarStepper .MuiStepConnector-root.Mui-disabled:first-child {
    /*.MuiStepConnector-line {*/
    border-color: #5372e4;
}

.sidebarStepper .MuiStepLabel-label.MuiStepLabel-completed {
    font-weight: normal;
}

.sidebarStepper .stepperEnd {
    font-size: 16px;
}

.MuiSelect-select, .MuiOutlinedInput-adornedEnd {
    background-color: #fff !important;
}

svg.MuiStepIcon-root.MuiStepIcon-active,
svg.MuiStepIcon-root.MuiStepIcon-completed {
    color: #5372e4;
}

span.MuiButton-label span {
    padding: 0 5px;
}

.authenticationButtons {
    text-align: center;
}

.franceConnect {
    padding: 0 1px;
}

.franceConnect:after {
    background-color: #fbfbfb;
    position: absolute;
    right: 0;
    margin-right: -12px;
    top: 115px;
    content: "ou";
    color: #5372e4;
    font-size: 18px;
    font-weight: 500;
    text-transform: lowercase;
}

.alternativeMethods a {
    color: #fff;
}

.authenticationSection .franceConnect:after {
    display: none;
}

.franceConnectSection {
    border-right: 1px solid rgba(50, 50, 50, 0.2);
    padding-bottom: 30px;
}

.authenticationSection .franceConnectSection {
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.authenticationSection .ou {
    color: #fff;
}

@media only screen and (max-width: 768px) {
    .franceConnect:after {
        display: none;
    }

    .franceConnectSection {
        margin-bottom: 10px;
        padding-bottom: 20px;
        border-right: none;
        border-bottom: 1px solid rgba(50, 50, 50, 0.2);
    }
}

button.kep-login-facebook {
    width: 100%;
    margin: 0 auto 15px auto;
    display: block;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 0;
    font-family: Montserrat, Roboto, sans-serif;
    font-weight: 400;
    border-radius: 4px !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}

.googleLogin {
    width: 100%;
    padding: 2px !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}

.googleLogin:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, .5) !important;
    border: none;
}

.googleLogin div {
    left: 25px;
    top: 15px;
    margin: 0 !important;
    padding: 0 !important;
    position: absolute;
}

.googleLogin svg {
    /*top: 2px;*/
    /*left: 5px;*/
}

.googleLogin span {
    width: 100%;
    padding: 10px !important;
    line-height: 24px;
    font-size: 16px;
    font-weight: 400 !important;
    font-family: Montserrat, Roboto, sans-serif;
}

div#g-recaptcha {
    transform-origin: center !important;
}

/*button.kep-login-facebook:after {*/
/*content: "ou";*/
/*position: absolute;*/
/*right: 0;*/
/*margin-right: -18px;*/
/*color: #7048e8;*/
/*font-size: 18px;*/
/*font-weight: 500;*/
/*text-transform: lowercase;*/
/*}*/

button.kep-login-facebook:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, .5);
}

@media only screen and (max-width: 768px) {
    button.kep-login-facebook {
        width: 100%;
    }
}

.ou {
    content: "ou";
    font-size: 18px;
    font-weight: 500;
    text-transform: lowercase;
    padding-bottom: 15px;
}

@media only screen and (max-width: 576px) {
    .ou {
        content: "ou";
        font-size: 18px;
        font-weight: 500;
        text-transform: lowercase;
        padding-bottom: 5px;
    }
}

@media only screen and (min-width: 576px) {
    button.kep-login-facebook {
        line-height: 29px;
        min-width: 80%;
    }
}

@media only screen and (max-width: 575px) {
    button.kep-login-facebook:after {
        display: none;
    }
}

p.f14 {
    font-size: 14px
}

p.f15 {
    font-size: 15px;
}

p.w500 {
    font-weight: 500;
}

.fa.ptz {
    position: absolute;
    top: 3px;
    right: 3px;
}

.link {
    cursor: pointer;
    color: #5372e4;
}

.link:hover {
    text-decoration: underline;
    cursor: pointer;
}

.noWrap {
    white-space: nowrap;
}

.emailForm {
    width: 440px;
    max-width: 95%;
    margin: 0 auto;
    color: #fff;
}


.PersonalSpace #header {
    margin-bottom: 5px;
    /*background-color: #f8f8f8;*/
    /*background-color: #ffffff;*/
    background-color: rgb(253, 253, 253);
    display: none;
}

.PersonalSpace #header img.weDouLogo {
    margin-left: -15px;
    margin-top: 15px;
    margin-bottom: 10px;
    max-width: 130px;
    max-height: 80px;
}

.PersonalSpace #header .logoContainer {
    justify-content: left !important;
}

.PersonalSpace .show-menu,
.PersonalSpace .hide-menu {
    top: 20px;
    left: 20px;
    position: absolute;
    color: #5372e4;
    font-size: 40px;
    z-index: 100;
    display: none;
}

/*.PersonalSpace .hide-menu {*/
/*    color: #ffffff;*/
/*}*/

@media screen and (max-width: 1024px) {
    .PersonalSpace div.navBar ul.menu {
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
        transition: margin-top .2s ease-in;
        -webkit-transition: margin-top .2s ease-in;
        z-index: 99;
        margin-top: -100%;
        background-color: #ffffff;
        box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    }

    .PersonalSpace div.navBar.active ul.menu {
        margin-top: 0;
    }

    .PersonalSpace div.navBar.active .hide-menu {
        display: block;
    }

    .PersonalSpace div.navBar:not(.active) .show-menu {
        display: block;
    }

    .PersonalSpace div.navBar ul.menu li {
        display: block;
        color: #5372e4;
    }

    .PersonalSpace div.navBar ul.menu li.active {
        height: 60px;
        border-bottom: none;
    }

    .PersonalSpace div.navBar ul.menu li.active span {
        padding: 5px 15px;
        border-left: 3px solid #ffffff;
    }

    /*.avatarContainer .logoContainer .accountContainer button {*/
    /*    margin-top: 23px;*/
    /*}*/
}

.PersonalSpace .navBarMobile {
    display: none;
}

.personalSideBar {
    margin-top: 75px;
}

.PersonalSpace .navBar .menu {
    width: 100%;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    color: #fff;
    list-style: none;
    text-align: center;
    text-decoration: none;

    cursor: pointer;
    font-size: 15px;
    line-height: 60px;
    text-transform: uppercase;
    display: inline-block;
}

.personalSideBar p {
    color: #ffffff;
}

.PersonalSpace .navBar .menu li {
    text-align: center;
    padding: 2px 10px;
}

.PersonalSpace .navBar .menu li:hover {
    cursor: pointer;
    text-decoration: underline;
}

.PersonalSpace .navBar .menu li.active {
    height: 55px;
}

.mainPersonalContainer {
    /*padding: 30px 60px;*/
    background-color: #fff;
}

@media screen and (max-width: 768px) {

    .mainContainer div.content {
        padding-left: 25px !important;
        padding-right: 25px !important;
        padding-top: 0;
    }

    .mainPersonalContainer {
        /*padding: 30px 30px;*/
    }

    div.MuiDialog-paperWidthSm,
    div.MuiDialog-paperWidthMd {
        margin-left: 25px;
        margin-right: 25px;
    }
}

.mainPersonalContainer .header {
    font-weight: normal;
}

.mainPersonalContainer .MuiPaper-root.personalPaper {
    margin-bottom: 40px;
    color: #5372e4;
    background: #ffffff;
}

.documents .MuiPaper-root.personalPaper {
    color: #5372e4;
    background: #ffffff;
    /*color: #ffffff;*/
    /*background: #3451b9;*/
}

.documents .personalPaper .MuiTableCell-body {
    color: #5372e4;
    /*color: #ffffff;*/
}

.documents .question-icon {
    /*color: #ffffff;*/
}

.MuiPaper-root.personalPaper .paper {
    padding: 15px 30px;
    background: #5372e4;
    color: white;
}

.MuiPaper-root.personalPaper .paper.light {
    background: #2daddd;
}

.mainPersonalContainer .MuiPaper-root.personalPaper.paperRows {
    padding: 0 15px;
    color: #ffffff;
    background: #5372e4;
}

.paperRows > div {
    padding: 0 15px;
}

/*.mainPersonalContainer .buttonSection .formButton {*/
/*    width: 100%;*/
/*    padding-top: 5px;*/
/*    padding-bottom: 5px;*/
/*    color: #000;*/
/*    !*border-width: 3px;*!*/
/*    !*border-style: solid;*!*/
/*    !*border-color: #4776e6 #8d54e9 #8d54e9 #4776e6;*!*/
/*    background-color: transparent;*/
/*    !*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4776e6", endColorstr="#8e54e9", GradientType=0);*!*/
/*    font-weight: 400 !important;*/
/*}*/
.subtitle {
    font-weight: 700;
    color: rgb(45, 50, 45);
    font-size: 19px;
}

.mainPersonalContainer .semiBold {
    font-family: Montserrat, sans-serif;
    color: #1e2053;
    font-weight: 600;
    font-size: 1.9em;
    text-align: center;
}

table.documentsTable .name,
table.documentsTable .download {
    font-size: 18px;
}

table.documentsTable .download i {
    cursor: pointer;
    font-size: 30px;
    margin-right: 10px;
}

/*table.documentsTable .documentsBody tr:hover {*/
/*    background-color: white;*/
/*}*/

label.formButton {
    width: 100%;
}

/*iframe.sign {*/
/*    width: 100vw;*/
/*    height: 800px;*/
/*    margin-top: -30px;*/
/*    margin-left: -60px;*/
/*    border: none;*/
/*}*/
iframe.sign {
    margin-top: 50px;
    width: 1400px;
    max-width: 100%;
    height: 1000px;
    border: none;
}

.tableAnimated {
    padding: 0;
    margin: 20px 0;
    /*min-height: 380px !important;*/
}

.tableAnimated .spanAnimated {
    height: 0;
    opacity: 0;
    list-style: none;
    display: block;
}

.tableAnimated .spanAnimated.show {
    /*height: 2em;*/
    height: auto;
    opacity: 1;
    transition: all 0.4s ease-in;
}

.tableAnimated .col {
    /*border: 1px solid rgba(150,150,150,0.2);*/

    border-bottom: none;
    background: #fff;
}

.tableAnimated .col.last {
    border-bottom: 1px solid rgba(150, 150, 150, 0.2);
}

.tableAnimated .colWrap {
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}

.tableAnimated p {
    margin: 12px;
}

@media screen and (max-width: 768px) {
    .tableAnimated {
        margin-top: 10px;
    }

    .tableAnimated p {
        margin: 16px 0;
    }
}

.MuiCheckbox-root.checkbox.Mui-checked .MuiSvgIcon-root {
    color: #5372e4;
}

li.MuiListItem-gutters {
    padding: 14px 16px;
}

li.MuiListItem-gutters.riskyProfessions {
    padding: 6px 16px;
}

.calendarWrapper:hover .calendarTip {
    display: block;
}

.calendarTip.show {
    display: block;
}

.calendarTip {
    display: none;
    /*display: block;*/
    width: 80%;
    text-align: center;
    font-size: 14px;
    margin-left: 10%;
    position: absolute;
    top: -110px;
    border-radius: 5px;
    color: #ffffff;
    left: 0;
    background: #5372e4;
    z-index: 1000000000;
}

.calendarTip:after {
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    display: inline-block;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #5372e4;
    margin: -1px auto 0 auto;
}

@media screen and (max-width: 768px) {
    div.calendarTip {
        position: fixed;
        top: 100px;
    }

    /*div.calendarTip:after {*/
    /*margin: 31px auto 0 auto;*/
    /*}*/
}

@media screen and (max-width: 320px) {
    div.calendarTip {
        top: 30px;
    }
}

.phoneRegistration .PhoneInputInput {
    height: 39px;
    border-radius: 1px;
}

#tidio-chat-iframe {
    bottom: 110px !important;
}

.no-wrap {
    white-space: nowrap;
}

.MuiPaper-rounded {
    border-radius: 30px !important;
}
.white-no-header {
    width: 100%;
    min-height: 450px;
}
.content-no-header {
    min-width: 100%;
    min-height: 450px;
    color: #fff;
    background-color: #5372e3;
}

.content-no-header div.MuiFormControl-root label.MuiInputLabel-root.MuiInputLabel-formControl {
    height: 5px;
    color: #fff !important;
}

.content-no-header p.MuiFormHelperText-root {
    color: #fff !important;
}


.incomeRow {
    /*margin: 30px 200px auto;*/
    /*max-width: 75%;*/
    /*margin: 0 auto;*/
    padding: 30px;
}

.roundedIcon {
    color: rgb(50, 206, 155);
    border: 1px solid rgb(50, 206, 155);
    border-radius: 50%;
    padding: 10px;
    margin: 0 5px;
}

.underscoreSvg {
    width: 175px;
}
/*#tidio-iframe*/
/*li.show:nth-child(2) {*/
/*    transition-delay: 0.4s;*/
/*}*/

/*li.show:nth-child(3) {*/
/*    transition-delay: 0.8s;*/
/*}*/
