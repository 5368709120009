.documentsFrame {
    position: static;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
    padding: 25px;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: -1px 6px 40px 0 rgb(118 126 173 / 34%);
    opacity: 1;
    -webkit-transform: translateZ(0) scaleX(1) rotateX(
0deg) rotateY(
0deg) rotate(
0deg) skew(
0deg,
0deg);
    transform: translateZ(0) scaleX(1) rotateX(
0deg) rotateY(
0deg) rotate(
0deg) skew(
0deg,
0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}