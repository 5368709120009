.smoker {
    margin-left: 15px;
}
.professions .MuiTextField-root,
.riskyProfessions .MuiTextField-root,
.riskyProfessions .MuiFormControl-root {
    max-width: 450px;
}

.professions .MuiSelect-select.MuiSelect-selectMenu,
.riskyProfessions .MuiSelect-select.MuiSelect-selectMenu {
    min-width: 300px;
    box-sizing: border-box;
}

.MuiInputLabel-formControl {
    padding: 0 !important;
    color: #585858 !important;
}
@media screen and (max-width: 400px) {
    .professions .MuiSelect-select.MuiSelect-selectMenu,
    .riskyProfessions .MuiSelect-select.MuiSelect-selectMenu {
        min-width: 235px;
    }
}

.riskyProfessions .MuiIconButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.54);
}
/*.riskyProfessions:first-child {*/
/*    color: #3451b9;*/
/*}*/
.profession-question {
    margin-right: 30px;
}
.profession-question-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.titleModalQuest {
    font-size: 22px;
    text-align: left;
    font-weight: 600;
    color:#2e305f;
} 

@media only screen and (max-width: 400px) {
    .profession-selector {
        max-width: 600px;
        flex: 1;
        padding: 0 15px;
    }
  }

  @media only screen and (min-width: 400px) {
    .profession-selector {
        min-width: 300px;
        max-width: 600px;
        flex: 1;
        padding: 0 15px;
    }
  }