.personalPaper .question-icon i{
    margin: 0 10px;
    font-size: 16px;
}

.personalLink {
    color: black;
    font-weight: 500;
    border-bottom: 1px solid #ffffff;
    padding: 0 5px;
    cursor: pointer;
    display: inline-block;
}

.personalFrame {
    position: static;
    right: 20px;
    bottom: 0;
    padding: 20px;
    margin-top: 40px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: -1px 6px 40px 0 rgb(118 126 173 / 34%);
    opacity: 1;
}

.personalLink:hover {
    cursor: pointer;
    text-decoration: underline;
}
.header{
    text-align: center;
}

.renunciaton {
    cursor: pointer;
    padding: 5px;
    display: inline-block;
    margin-top: 50px;
}

.renunciaton:hover {
    border-radius: 8px;
    background-color: white;
    color: black;    
}

.contrat {
    border: 2px solid #00d57c;
    border-radius: 20px;
    font-weight: 600;
    padding: 5px;
    color: #00d57c;
}

.personalHomeFrame {
    border: 1px solid #9badee;
    color: white;
    text-align: center;
    font-weight: 500;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
}

.dateSignature {
    border-radius: 20px;
    padding: 5px;
    color: rgb(55, 51, 85); 
    box-shadow: 0px 0px 9px 0 rgb(118 126 173 / 34%);
}

.resignationQuestions .radioButton {
    width: 100%;
}

.renunciation {
    position: fixed;
    bottom: 0;
    left: 275px;
    cursor: pointer;
}