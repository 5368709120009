.focusText {
    font-size: 24px;
    text-align: center;
}

.focusText.underlined {
    text-align: center;
    padding: 35px 0;
    margin: 10px 30px;
    border-bottom: 1px solid #999;
}

.healthQuestion {
    font-size: 17px;
    line-height: 1.5;
    color: #2e305f !important;
}
.radioButton span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 > span{
    color: #2e305f !important;
}
.clickedButton {
    border: 2px solid #75ACBA !important;
    border-radius: 10px;
    color: #75ACBA;
    padding: 2px 13px;
    font-weight: bold !important;
    text-align: center;
}

.grayFrame {
    background-color: #fafbff;
    border-radius: 20px;
    padding: 15px;
}

.questCol {
    margin-bottom: 3vh;
}

@media only screen and (min-width: 600px) {
    .questCol .radioButton {
        margin-right: 1.5vw;
    }
}


.radioButton {
    border: 1px solid #d1d8e0;
    border-radius: 10px;
    padding: 2px 13px;
    font-weight: bold !important;
    text-align: center;
}

.radioButton:hover {
    border: 2px solid #4c66c8;
    border-radius: 10px;
    background-color: #f8fafc;
    font-weight: bold !important;
    text-align: center;
}

.styleCheckBox {
    padding: 20px;
}

.smallFont {
    font-size: 12px;
    line-height: 1.5;
    text-align: justify;
}
