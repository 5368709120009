.preButtonText {
    font-size: 14px;
    text-align: center;
    color: #75ACBA;
    line-height: 1;
}
.buttonImage {
    width: 100%;
    height: 47px;
    text-align: center;
    background: #034EA2;
    border: 1px solid #1B1464;
    border-bottom: 4px solid #1B1464;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}
.buttonImage:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, .5);
}
.svg {
    height: 49px;
    cursor: pointer;
}
.whatIsFranceConnect {
    font-size: 13px;
    width: 100%;
    text-align: center;
    display: inline-block;
}

@media only screen and (max-width: 937px) {
    .preButtonText {
        line-height: 19px;
    }
}
@media only screen and (max-width: 843px) {
    .preButtonText {
        padding: 0 33px;
        line-height: 18px;
        /*letter-spacing: 1.2px;*/
    }
}
@media only screen and (max-width: 768px) {
    .preButtonText {
        font-size: 15px;
    }
    .buttonImage {
        height: 44px;
    }

    .svg {
        height: 45px;
    }
}
