.row {
    padding-left: 30px;
    padding-right: 30px;
}

.creditsSubtitle h3 {
    text-align: center;
    font-weight: normal;
}

.Mui-focused fieldset legend,
.MuiFormLabel-filled + div fieldset legend {
    padding-right: 15px !important;
}

.creditsColumn {
    text-align: center;
}

.creditsBlock {
    text-align: left;
    margin: 10px 0 30px 0;
}

.creditsBlock h4 {
    margin-block-start: 0.8rem !important;
    margin-block-end: 0;
}

.creditsBlock label {
    width: 100%;
    text-align: center;
    display: inline-block;
}

.creditsBlock img {
    width: 80px;
    cursor: pointer;
    display: inline-block;
}

/* .creditsBlock .inactive { */
/* opacity: 0.5; */
/* border: none; */
/* } */
.creditsBlock .wrapper {
    max-width: 7rem;
    margin: 0 auto;
    padding-bottom: 5px;
}

.creditsBlock .active .wrapper {
    border-bottom: 7px solid #5372e4;
}

.fieldsHeader {
    margin-top: -35px;
}

.paddingBottom {
    padding-bottom: 35px;
}

@media only screen and (max-width: 768px) {
    .fieldsHeader {
        padding-top: 35px;
        padding-bottom: 15px;
    }

    .loan-selectors {
        display: block;
    }
}

.pretTitle {
    font-size: 32px;
    font-family: Montserrat, sans-serif;
    color: #1e2053;
    font-weight: 600;
    text-align: center;
}

.step1subtitle {
    margin: 20px 0 30px;
    font-size: 20px;
    color: #000000;
    display: inline-block;
}

.step1subtitle strong {
    /*color: #7048e8;*/
}

button.floatingBackButton {
    top: 13px;
    background-color: #e9ecef;
}

button.floatingBackButton i.backIcon {
    color: #adb5bd;
    font-size: 40px;
    font-weight: bold;
    margin-left: -3px;
}

.step1 .checkboxes {
    margin: 25px 0;
    text-align: left;
}

.step1 .checkboxes > div {
    text-align: center;
}

.title {
    margin-bottom: 10px;
    color: #666666;
    font-weight: 600;
}

.titlePrets {
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 900;
    max-width: 450px;
    font-size: 35px;
    text-align: center;
    color: rgb(45, 50, 45);
    display: inline-block;
}

@media only screen and (max-width: 576px) {
    .step1 .checkboxes > div {
        text-align: left;
    }
}

.ChooseFlowButtonGroup {
    margin-bottom: 25px;
}

.ChooseFlowButtonGroup .formButton {
    padding: 5px;
    opacity: .7;
}

.ChooseFlowButtonGroup .formButton.selected {
    opacity: 1;
    /*padding: 9px;*/
    /*margin-top: -2px;*/
}


.dialog-enter {
    opacity: 0.01;
    /* transform: scale(1.1); */
    margin-top: 100px;
}

.dialog-enter-active {
    opacity: 1;
    /* transform: scale(1); */
    margin-top: 0;
    transition: all 300ms ease-out;
}

.dialog-exit {
    opacity: 1;
    margin-top: 0;
    /* transform: scale(1); */
}

.dialog-exit-active {
    opacity: 0.01;
    /* transform: scale(1.1); */
    margin-top: -100px;
    transition: all 400ms ease-out;
}

.loan-number {
    color: #fff !important;
    width: 50px;
    line-height: 30px;
    text-align: center;
    height: 30px;
    margin: 0 10px 0 0;
    display: inline-block;
    border-radius: 8px;
    cursor: pointer;
    border: 3px solid rgb(63, 165, 238);
    font-weight: bold;
    -webkit-box-shadow: 0 2px 10px rgba(63, 165, 238, 0.2);
    box-shadow: 0 2px 10px rgba(63, 165, 238, 0.2);
    transition: border 0.15s ease-in-out;
}

.loan-number:hover {
    border: 3px solid #fff;
}

.triangle:hover {
    font-weight: 500;
    padding: 15px 30px;
    color: #5372e4;
    border:2px solid #5372e4;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
}

.triangle {
    /* position: absolute;
    left: 20px;
    top: 20px; */
    text-align: center;
    width: 6vw;
    padding: 15px 30px;
    border: 2px solid #edf1f8;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 16%);
    font-family: Montserrat, sans-serif;
    color: #819ab4;
    font-size: 20px;
    font-weight: 600;
}
    .pretsFrame {
        padding: 25px 40px;
        margin-top: 8vh;
        border-radius: 25px;

        /*background-image: linear-gradient( 157deg , #5372e4, rgb(63, 165, 238, 0.9));*/

        box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
    }

@media only screen and (max-width: 400px) {
    .pretsFrame {
        padding: 25px 37px;
        margin-top: 8vh;
        border-radius: 25px;
        background-color: white;
        box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
    }
  }



.loan-number.active {
    border: 3px solid #fff;
}

/*.additionalLoan:before {*/
/*    content: "";*/
/*    position: absolute;*/
/*    margin-left: -25px;*/
/*    margin-top: 50px;*/
/*    color: #5392e8;*/
/*    font-weight: bold;*/
/*}*/
/*.additionalLoan0:before {*/
/*    content: "#1";*/
/*}*/
/*.additionalLoan1:before {*/
/*    content: "#2";*/
/*}*/
/*.additionalLoan2:before {*/
/*    content: "#3";*/
/*}*/
/*@media screen and (max-width: 768px) {*/
/*    .additionalLoan:before {*/
/*        display: none;*/
/*    }*/
/*}*/


.MuiInput-underline:before {
    border-bottom: none !important;
}

/*.MuiInputBase-input.MuiSelect-select {*/
/*    border: 1px solid rgb(225,225,225);*/
/*}*/
.MuiTextField-root .MuiInputBase-root.MuiInput-root, .MuiFormControl-root .MuiInputBase-root.MuiInput-root {
    border: 1px solid rgb(225, 225, 225);
}

.smallerText {
    font-size: .8rem;
    line-height: 1.5rem;
    color: rgb(102, 102, 102);
}

.greenPrice {
    color: rgb(50, 206, 155);
    font-size: 1.4rem;
}

.tableRow {
    margin: 10px 0;
}

.mainPrice {
    font-weight: 500;
    font-size: 2rem;
    color: #000;
}

.optionsButton {
    border-radius: 15px !important;
    box-shadow: none !important;
    border: none !important;
    padding: 10px 17px !important;
    text-transform: none !important;
}
.optionsButton:not(.selected) {
    background: none !important;
}
.MuiTimelineItem-missingOppositeContent:before {
    content: none !important;
}

.selected {
    background: #F6B60D;
    border-radius: 35px !important;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 10px 24px !important;
}

.radioText {
    font-size: .95rem !important;
    line-height: 2.2rem !important;
    font-weight: 500;
    color: rgb(45, 50, 45) !important;
    padding: 20px 0;
}

.radioSelectionText {
    font-size: .95rem !important;
    color: rgb(45, 50, 45);
}

.MuiTimelineItem-missingOppositeContent:before {
    flex: 0 !important;
    margin-left: 28%;
}
.MuiTimelineDot-defaultPrimary
{
    background-color: rgb(50, 206, 155) !important;
}
.MuiTimelineDot-root {
    border: 1px solid rgb(50, 206, 155) !important;
}
.MuiSlider-root {
    color: #F6B60D !important;
}
.MuiTimelineContent-root {
    padding: 10px 16px !important;
}
