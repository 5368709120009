.footer {
    padding: 0;
    text-align: right;
    margin-top: 50px;
    bottom: 0;
    position: absolute;
    width: 100%;
    left: 0
}

.linkArea {
    padding-right: 100px;
}

.link {
    font-size: 16px;
    color: #999;
    /*padding: 0 10px;*/
    margin: 20px;
    text-decoration: none;
    display: inline-block;
}

.wedou_logo_footer {
    max-width: 70px;
    max-height: 70px;
    display: inline-block;
    vertical-align: middle;
}

.login {
    font-size: 16px;
    margin: 0px 0 20px 20px;
    text-decoration: underline;
    color: #5372e4;
    cursor: pointer;
    display: inline-block;
}

.link span {
    border-bottom: 1px solid #999;
}

.link:hover {
    border-bottom: none;
}

.personalFooter {
    color: #fff;
    max-width: 100%;
    min-height: 200px;
    height: auto;
    padding: 0px 0 0px 0;
    font-size: 16px;
    font-weight: 400;
    z-index: 100;
    overflow: hidden;
    margin-left: 300px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4776e6+0,8e54e9+100 */
    /*background: #3451b9; !* Old browsers *!*/
    /*background: -moz-linear-gradient(top, #4776e6 0%, #8e54e9 100%); !* FF3.6-15 *!*/
    /*background: -webkit-linear-gradient(top, #4776e6 0%, #8e54e9 100%); !* Chrome10-25,Safari5.1-6 *!*/
    /*background: linear-gradient(to bottom, #4776e6 0%, #8e54e9 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!*/
    /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4776e6', endColorstr='#8e54e9', GradientType=0); !* IE6-9 *!*/

    /*Background-Colors Test*/
    /*-webkit-filter: contrast(80%) blur(0);*/
    /*filter: contrast(50%) blur(40);*/
    left: 0;
    width: 100%;
}

@media screen and (max-width: 1024px) {

    .personalFooter {
        position: relative;
        left: -1px;
        margin-left: 1px;
    }
}

.personalFooter > div {
    padding: 0 15px;
}

.personalFooter a {
    font-size: 18px;
    color: #fff;
    text-decoration: none;
}

.personalFooter a:hover {
    border-bottom: 1px solid black;
}

.personalFooter .head {
    font-size: 24px;
    font-weight: 500;
}

.personalFooter .copyright {
    padding-top: 0px;
    text-align: center;
    font-size: 14px;
}

.personalFooter .footerLogo {
    height: 60px;
}

.NoUnderline a:hover {
    border-bottom: 0px solid white;
}

.NoUnderline li {
    list-style: none;
    margin: 5px 10px;
    display: inline-block;
    font-size: 30px;
    padding-left: 20px 10px;
    color: #fff;
    border: 0px solid #fff;
    border-radius: 50%;
    transition: .5s;
}

.NoUnderline li:hover {
    color: #292929;
    border: 0px solid #292929;
    transition: .5s;
}
