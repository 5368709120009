.body-info {
    padding-top: 10px;
    width: 400px;
}

.errors {
    color: red;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 10px;
    font-weight: bold;
}

.form1Mobile {
    border: 2px solid #5372e4;
    color: #5372e4;
    background-color: white;
    width: 32%;
    margin: auto;
    margin-left: 1%;
    border-radius: 9px;
    margin-top: 10px;
    min-height: 3.4vh;
    font-size: 13px;
    text-align: center;
}

.white-button {
    width: 100%;
    background-color: white;
    color: #5372e4;
    font-size: 22px;
    font-weight: 500;
    padding: 10px;
    cursor: pointer;
    border-radius: 15px;
    border: 1px solid #5372e4;
}

.white-button:hover {
    background-color: #5372e4;
    color: white;
}

.wedouText {
    color: #75ACBA;
}

.containerRendezVous {
    /*max-width: 800px;*/
    margin: 0 auto;
}

@media screen and (max-width: 768px) {
    .containerRendezVous {
        padding: 0;
    }

    .calendarRow, .calendarCol {
        padding: 3px !important;
    }
}

.calendarContainer {
    border: 2px solid #e5eaf2;
    /*box-shadow: 0 4px 8px 0 #e5eaf2, 0 6px 40px 0 #f0f0fb;*/
    border-radius: 20px;
    background: #fff;
}

.container {
    border-radius: 4px;
    border: 1px solid #f3f3f1;
    /* margin-top: 10px; */
    background-color: white;
}

.title-template {
    border-bottom: 2px solid black;
    padding: 0 0 5px 10px;
    margin-bottom: 20px;
}

.img-template {
    border: 1px solid #555;
    margin: 10px;
    height: auto;
}

.title_calendar {
    font-size: 24px;
    line-height: 26px;
}

@media only screen and (max-width: 800px) {
    .title_calendar {
        font-size: 16px;
    }
}

.template {
    margin-bottom: 20px;
}

.containerMobile {
    width: 90%;
    margin: auto;
    border-radius: 4px;
    border: 1px solid #f3f3f1;
    margin-top: 30px;
    background-color: white;
}

.date {
    margin-left: 70px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 12px;
}

.form1 {
    border: 2px solid #5372e4;
    color: #5372e4;
    background-color: white;
    width: 25%;
    margin: auto;
    border-radius: 9px;
    margin-top: 10px;
    min-height: 3.4vh;
    font-size: 16px;
    text-align: center;
    padding: 2px 5px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.divForm {
    overflow: auto;
    max-height: 360px;
    display: flex;
    flex-direction: column;
}

.divFormMobile {
    /*height: 20vh;*/
}

#window-button {
    font-size: 16px !important;
    font-family: Montserrat, sans-serif;
    color: white;
    border-radius: 5px;
    font-weight: 500;
}

.form1:hover {
    background-color: #5372e4;
    color: white;
}

/* .form1--active {
    background: #1087ff;
    color: white;
  }
  .form1--active:enabled:hover,
  .form1--active:enabled:focus {
    background: #1087ff;
  }
.form1:enabled:hover,
.form1:enabled:focus {
  background-color: #1087ff;
} */

.image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 4em;
    height: 4em;
    padding-top: 25px;
    margin-bottom: 10px;
}

.rendez-vous {
    line-height: 1.3;
    font-weight: bold;
    font-size: 18px;
    color: #5380ed;
}

.label {
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
}

.top-title {
    font-weight: bold;
    line-height: 1.4;
    font-size: 18px;
}

.flex {
    display: flex;
    margin: 10px;
}

.calendar.react-calendar {
    max-height: 320px;
    max-width: 400px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 10px;
    border-color: violet;
}

.calendar.react-calendar .react-calendar__tile {
    padding: 10px 5px;
}

#simulation {
    margin-top: 10px;
    margin-bottom: 0px;
}

.body {
    height: 46vh;
    width: 400px;
    background-color: white;
    margin: auto;
}

.text {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
    margin-bottom: 3px;
    font-weight: bold;
}

.label-calendar {
    margin-top: 10px;
    font-size: 18px;
    color: #5d5d5d;
    letter-spacing: 1px;
    line-height: 25px;
}

#suivant-job {
    width: 240px;
    color: white;
}

.title-modal {
    text-align: center;
    margin-bottom: 20px;
    color: #18143b;
    font-size: 1.5em;
    font-weight: 500;
}

.modal-field {
    width: 240px;
}

.nested-label {
    margin-top: 9px;
    font-weight: bold;
}

.modal-title {
    margin-top: 5px;
}

#hours_container,
#minutes_container {
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

#hours_track,
#minutes_track {
    height: 245px;
    width: 50%;
    overflow: overlay;
    display: flex;
    flex-direction: column;
    z-index: 100;
}

#hours_track::-webkit-scrollbar,
#minutes_track::-webkit-scrollbar {
    display: none;
}

#hours_track,
#minutes_track {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

#hours_track .page,
#minutes_track .page {
    height: 35px;
    line-height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /*color: rgba(0, 0, 0, 0.54);*/
    color: #000;
}

.selected span {
    color: #fff !important;
}

.containerRendezVous #hours_track .page.selected,
.containerRendezVous #minutes_track .page.selected,
.container #hours_track .page.selected,
.container #minutes_track .page.selected {
    color: #fff;
    font-weight: 500;
}

#hours_track .page {
    justify-content: flex-end;
    padding-right: 7px;
    margin: 0 7px;
}

#minutes_track .page {
    justify-content: start;
    padding-left: 14px;
}

.dummy-content {
    height: 35px;
}

.scroll_middle_selector {
    position: absolute;
    width: 100%;
    height: 35px;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    color: #007aff;
    line-height: 35px;
    font-weight: 600;
    z-index: 100;
}

.scroll_middle_selector_hours,
.scroll_middle_selector_minutes {
    width: 75px;
    height: 100%;
    /*background: rgba(100,100,100,0.2);*/
    background: rgba(255, 255, 255, 0.7);
    border-radius: 8px;
    margin: 0 2px;
    display: inline-block;
    vertical-align: middle;
}

.scroll_middle_selector_minutes {
    margin-left: 2px;
}

.scroll_middle_selector_dots {
    display: inline-block;
    vertical-align: middle;
    width: 8px;
    text-align: center;
    color: #fff;
}

.minutes-header {
    font-size: 15px;
    margin: 0;
}

.container h4,
.container .minutes-header,
.container .scroll_middle_selector_dots {
    color: #5380ed;
}

.container .scroll_middle_selector_hours,
.container .scroll_middle_selector_minutes {
    background: rgba(100, 100, 100, 0.2);
}

.container #hours_track .page,
.container #minutes_track .page {
    color: rgba(0, 0, 0, 0.54);
}

.containerRendezVous h4,
.containerRendezVous .minutes-header {
    color: #75ACBA;
}

#hours_track .page.selected:after {
    content: "h";
}

#hours_track.bg .page.selected:after {
    content: "ч";
}

#minutes_track .page.selected:after {
    content: "m";
}

#minutes_track.bg .page.selected:after {
    content: "м";
}

@media only screen and (min-width: 768px) {
    .minutes-header {

        height: 45px;
    }
}

@media only screen and (max-width: 768px) {
    .body-info {
        width: auto;
    }

    .minutes-header {

        height: 15px;
    }

    .calendar-wrapper {
        margin-top: 60px;
    }

    #hours_track,
    #minutes_track {
        height: 175px;
    }
}
