.cookieBar {
    /* background-color: #8e54e9 !important; */
    /*left: 50vw!important;;*/
    right: 0!important;
    bottom: 0!important;
    /*transform: translate(-50%, 0);*/
    /*transform: translate(-50%, -50%);*/

    height: 350px !important;
    width: 250px !important;
    /*padding: 30px 10px;*/
    padding: 0 10px;
    margin-right: 20px;
    margin-bottom: 20px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4776e6+0,8e54e9+100 */
    /*background: #4776e6; !* Old browsers *!*/
    /*background: -moz-linear-gradient(top, #4776e6 0%, #8e54e9 100%); !* FF3.6-15 *!*/
    /*background: -webkit-linear-gradient(top, #4776e6 0%,#8e54e9 100%); !* Chrome10-25,Safari5.1-6 *!*/
    /*background: linear-gradient(to bottom, #4776e6 0%,#8e54e9 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!*/
    align-items: center;
    border-radius: 5px;
    box-shadow: 1px 1px 11px 0 #000;
    color: black;
    /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4776e6', endColorstr='#8e54e9',GradientType=0 ); !* IE6-9 *!*/
    position: fixed !important;
    z-index: 9999999999 !important;
}

.cookieBar .cookie-message {
    font-size: 15px;
    line-height: 1.25em;
    font-weight: 400;
    text-align: center;
    letter-spacing: normal;
}

.button-close {
    display: block !important;
    width: 120px !important;
    height: 40px;
    color: white !important;
    border-radius: 20px !important;
    background-color: #ECB941 !important;
    text-align: center !important;
    cursor: pointer !important;
    margin: auto !important;
}

.modalCookie .MuiDialog-paper {
    overflow: visible;
    background-color: #F3F0B0 !important;
}
.cookieBar .button-close {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    font-family: 'Montserrat' !important;
    margin: auto !important;
    font-weight: 600 !important;
    font-size: 14px !important;
}

.titleCookie {
    font-weight:bold;
    text-align:center;
    margin-right:40px;
    margin-left:40px;
}

.infoCookie {
    position: absolute;
    top: -90px;
    right: -60px;
    width: 200px;
    height: 100px;
    background-color: #5372e4;
    color: white;
    display: none;
}

.cookieButton {
    width: 100%;
    font-weight: 400!important;
    height: 40px;
    color: white!important;
    margin-top: 20px!important;
    text-transform: capitalize!important;
    font-size: 20px!important;
}

.checkboxCookie {
    text-align: center!important;
}

.cookieButtonOther {
    font-weight: 400!important;
    height: 40px;
    width: 100%;
    margin-top: 10px!important;
    text-transform: capitalize!important;
    font-size: 20px!important;
}
.cookieBar + #cookieBackground {
    display: block;
}
#cookieBackground {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    position: fixed;
    background-color: gray;
    z-index: 1299;
    display: none;
}
