.steps .fileRow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    padding: 15px 40px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(129, 154, 180, 0.4);
    border-radius: 10px;
}

.reactIcons {
    margin-right: 15px;
}

.stepsInfo {
    padding: 26px;
    border-radius: 20px;
    background-color: #fafbff;
}

.steps {
    width: 80%;
    margin: 40px auto;
    padding: 35px 40px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 6px 30px 0 rgb(59 61 100 / 16%);
}

.steps .fileRow,
.steps .ibanRow {
    margin-top: 30px;
}

.steps .ibanRow .fa-question-circle {
    font-size: 15px;
}

.personal_nav {
    text-align: center;
    margin-top: 20px;
}

.personal_nav_span {
    color: #2e305f;
    font-size: 20px;
}

.active_steps {
    color: #5372e4;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    /*border-bottom: 3px solid #5372e4;*/
    text-decoration: underline;
    padding: 5px;
}

.transferredButton {
    padding: 10px 20px;
    display: inline-block;
    margin-top: 10px;
    cursor: pointer;
    border-style: solid;
    border-width: 2px;
    border-color: #00d57c;
    border-radius: 10px;
    background-color: #00d57c;
    font-family: Montserrat, sans-serif;
    color: white;
    font-size: 16px;
    width: 100%;
    font-weight: 600;
    text-align: center;
}

.tickIcon {
    background-color: white;
    color: #00d57c;
    border-radius: 20px;
}

.personal_nav_span:hover {
    color: #5372e4;
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
}
label.stepsButton *,
.transferredButton * {
    vertical-align: middle;
}
.stepsButton {
    padding: 10px 20px;
    display: inline-block;
    margin-top: 10px;
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    background-color: #fff;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    width: 100%;
    font-weight: 600;
    text-align: center;
}

.stepsButton:hover {
    color: white;
    box-shadow: 0 6px 30px 0 rgb(59 61 100 / 16%);
    cursor: pointer;
}

.steps .fileRow p.name {
    color:#2e305f;
    font-size: 17px;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    padding: 20px 15px;
    align-items: center;
}

.stepsImg {
    display: inline-block;
    margin-right: 15px;
}

.steps .fileRow p.missing {
    color: red;
    font-size: 18px;
    font-style: italic;
}

.steps .fileRow p.transferred {
    color: green;
    font-size: 18px;
    font-style: italic;
}

.steps .fileRow p.name,
.steps .fileRow p.missing,
.steps .fileRow p.transferred {
    margin: 0;
    font-size: 16px;
}

.steps .infoText {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: Montserrat, sans-serif;
    color: #2e305f;
    font-size: 16px;
    font-weight: 500;
}
