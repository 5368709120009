.container {
    font-family: Poppins;
    letter-spacing: 0.40px;
    font-weight: 900;
    justify-content: center;
    color: #88BAC7;
    display:flex;
}
.big {
    font-size: 69px;
    position: relative;
    bottom: 8px;
}
.medium {
    font-weight: 600;
    font-size: 40px;
}
.small {
    position: relative;
    bottom: 10px;
    right: 6px;
    font-size: 20px;
}
