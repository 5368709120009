.fc_logo {
    height: 80px;
    /*margin: 50px auto 0 auto;*/
    /*display: block;*/
    margin: 30px auto 0 auto;
    display: block;
    vertical-align: middle;
}

.bpi_france {
    height: 65px;
    /*margin: 50px auto 0 auto;*/
    /*display: block;*/
    margin: 30px auto 0 auto;
    display: block;
    vertical-align: middle;
}

.loaderPages {
    font-family: Poppins-Light;
    margin-left: 25px;
    display: block;
    font-size: 13px;
    font-weight: 0;
    text-transform: uppercase;
    margin-bottom: 21px;
    cursor: pointer;
}

.phoneNumber {
    text-align: left;
    height: 20px;
    line-height: 15px;
    padding: 15px 5px;
    width:220px;
    color: #FE7A59;
    margin:auto !important;
    margin-bottom: 15px !important;
}

.loader {
    margin-left: 20px;
    margin-top: 10px;
    text-align: left;
    margin-bottom: 30px;
    width: 12px;
    border-radius: 15px;
    height: 200px;
}

.calendarModal {
    width: 100%;
}

.sidebar_title {
    text-align: center;
    font-weight: 600;
    font-size: 2vh;
    color: #4c4d76;
    margin-top: 1vh;
}

.sidebar_list {
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: black;
}

 .inputSideBar {
    color: #627acc !important;
    text-align: right;
}

.besoin {
    width: 220px;
    text-align: left;
    height: 20px;
    line-height: 15px;
    padding: 15px 5px;
    border-style: solid;
    border-width: 1px;
    border-color: hsla(0, 0%, 100%, 0.18);
    border-radius: 50px;
    color:white;
    margin: auto !important;
    margin-bottom: 15px !important;
}

.besoin:hover {
    cursor: pointer;
    background-color: white;
    text-align: left;
    line-height: 20px;
    border-radius: 25px;
    color:black;
}

.wedou_logo {
    max-width: 234px;
    max-height: 104px;
    cursor: pointer;
    /*margin: 45px auto 0 auto;*/
    /*display: block;*/
    /*margin: 50px auto 0 auto;*/
    /*padding-right: 35px;*/
    display: inline-block;
    vertical-align: middle;
}

.sidebarList {
    color: white;
}
