.loaderContainer {
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1400;
}

.loader {
    max-width: 50%;
}

.loaderLogo {
    max-width: 100px;
    margin-top: 50%;
    margin-left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes ldsEclipse {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes ldsEclipse {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.ldsEclipse {
    width: 100%;
    height: 100%;
    position: relative;
}

.ldsEclipse div {
    position: absolute;
    -webkit-animation: ldsEclipse 1s linear infinite;
    animation: ldsEclipse 1s linear infinite;
    width: 180px;
    height: 180px;
    top: 10px;
    left: 10px;
    border-radius: 50%;
    -webkit-transform-origin: 90px 92.5px;
    transform-origin: 90px 92.5px;
}

.ldsEclipse {
    width: 200px !important;
    height: 200px !important;
    -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
    transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}
