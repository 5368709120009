.animateEntry {
    opacity: 0;
    transition: .5s opacity linear;
}
.animateEntry.done {
    opacity: 1;
}

.modal-text-wrapper {
	display: flex;
	flex-wrap: wrap;
    font-size: 0.9em;
	justify-content: space-between;
	margin: 10px 0;
    font-family: 'Montserrat' !important;
}

.guarantiesFrame {
    padding: 25px 40px;
    margin-top: 8vh;
    border-radius: 25px;
    background-color: white;
    box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
}

.comparisonFrame {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    padding: 35px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 40px;
    background-color: #fff;
    box-shadow: 0 4px 60px 0 rgb(83 114 227 / 8%), 1px 1px 26px 0 rgb(0 0 0 / 5%);
    margin-top: 5vh;
}

.comparisonButton {
    margin: 4vh 0px 1vh 0px;
    width: 100%;
    height: 53px;
    background-color: white;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
    font-weight: 600;
}

.selectedInsurerButton {
    margin: 4vh 0px 1vh 0px;
    width: 100%;
    min-width: 200px;
    cursor: pointer;
    height: 53px;
    color: #ffffff;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
}
.comparisonButton:hover {
    width: 100%;
    height: 53px;
    background-color: ghostwhite;
    color: white;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
}
