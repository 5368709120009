p.label {
    font-size: 18px;
}
.sectionHeading p {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    margin-block-start: 0.3rem;
}

.buttonSection {
    padding-top: 25px;
    padding-left: 22px;
}

.loanSection span {
    margin-left: 20px;
}

.loanSection .loanAmount {
    font-weight: bold;
    font-size: 18px;
}

.titleInstallments{
    text-align: center;
    color: black;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    font-family: 'Montserrat' !important;
}

p.or {
    position: relative;
    height: 50px;
    line-height: 50px;
    text-align: center;
}
p.or::before,
p.or::after {
    position: absolute;
    width: 43%;
    height: 1px;
    top: 24px;
    background-color: #aaa;
    content: '';
}
p.or::before {
    left: 0;
}
p.or::after {
    right: 0;
}


.arrow {
    position: relative;
    margin: 0 auto;
    width: 100px;
}

.arrow .curve {
    border: 2px solid #BE5F4B;
    border-color: transparent transparent transparent #BE5F4B;
    height: 360px;
    width: 1200px;
    border-radius: 230px 0 0 150px;
}

.arrow .point {
    position: absolute;
    left: 40px;
    top: 315px;
}

.arrow .point:before, .arrow .point:after {
    border: 1px solid #BE5F4B;
    height: 25px;
    content: "";
    position: absolute;
}

.arrow .point:before {
    top: -11px;
    left: -11px;
    transform:rotate(-74deg);
    -webkit-transform:rotate(-74deg);
    -moz-transform:rotate(-74deg);
    -ms-transform: rotate(-74deg);
}

.arrow .point:after {
    top: -20px;
    left: 5px;
    transform:rotate(12deg);
    -webkit-transform: rotate(12deg);
    -moz-transform:rotate(12deg);
    -ms-transform: rotate(12deg);
}
.buttonGroup .formButton.lightBlueButton {
    background: #2daddd;
    border-color: #2daddd;
    color: white;
}

.mobileStep span.MuiFormControlLabel-label {
    font-size: 15px;
}

.economieWrapper {
    color: #000000;
    font-weight: 500;
}

.mobileStep .MuiIconButton-root {
    padding: 5px 10px;
}
.mobileStep button.MuiButtonBase-root.MuiButton-root.formButton.MuiButton-text {
    margin-bottom: 5px;
}

.voila {
    font-size: 17px;
    padding-top: 15px;
}
.insurancePopup {
    width: 800px;
}

ard {
	padding: 20px;
	border-radius: 40px;
	margin: 10px 10px;
	overflow: hidden;
    background-color: white;
}

.insurer-card-logo {
	height: 45px;
    text-align: center;
}

.insurer-card-logo-afiesca {
	height: 50px;
    text-align: center;
}

.insurer-card-logo-afiesca img {
	height: 2.3vw;
}

@media only screen and (max-width: 550px) {
    .insurer-card-logo-afiesca img {
        height: 50%;
    }
  }

.insurer-card-logo img {
	height: 100%;
}

@media only screen and (max-width: 550px) {
    .insurer-card-logo img {
        height: 60%;
    }
  }

.insurer-card-separator {
	border-color: #fff;
	margin: 20px 0 30px 0;
}

.insurer-card-row {
	display: flex;
	justify-content: space-between;
	margin: 10px 0;
	align-items: baseline;
	text-align: initial;
}

.insurer-card-row:last-child {
	margin-bottom: 0;
}

.insurer-card-first-row {
	display: flex;
	flex-direction: column;
}

.insurer-card-number {
	font-weight: bold;
    font-size: 1.7vw;
    font-family: Poppins-Medium;
    letter-spacing: -2px;
}

.insurer-card-number-mobile {
	font-weight: bold;
    font-size: 1.2rem;
}

.insurerTitle {
    margin-bottom: 5px;
    font-size: 1.2vw;
    color: #2e305f;
    font-family: Poppins-Medium, sans-serif;
    font-weight: 500;
}

.insurerTitleMobile {
    margin-bottom: 5px;
    font-size: 14px;
    font-family: Montserrat, sans-serif;
    color: #18143b;
    font-weight: 500;
}


.guarantees-cards-wrapper .insurer-card-separator {
	margin-bottom: 0;
}

.extra-guarantees-submenu {
	padding: 20px;
    font-weight: 600;
	margin: 0;
	text-align: center;
}

.guarantees-cards-wrapper .insurer-card-row{
	margin: 10px 0;
	align-items: flex-start;
}

.extra-guarantees-tooltip {
	min-width: 44px;
}
.dataTable {
    padding: 15px;
    text-align: left;
}
