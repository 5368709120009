.saveProgressWrapper {
    height: 200px;
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 9999999991;
    /*display: flex;*/
    /*flex-grow: 1;*/
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*align-content: center;*/
    /*justify-content: center;*/
    /*vertical-align: middle;*/
}

.saveProgressWrapper.expanded {
    width: 200px;
}

.saveProgressButton {
    width: 220px;
    text-align: left;
    height: 20px;
    line-height: 15px;
    padding: 15px 5px;
    border-style: solid;
    border-width: 1px;
    border-color: hsla(0, 0%, 100%, 0.18);
    border-radius: 50px;
    color: white;
}

.saveProgressButton:hover {
    cursor: pointer;
    background-color: white;
    color: black;
}

.saveProgressIcon {
    color: white;
}

.updateWrapper {
    width: 100%;
    bottom: 60px;
    position: absolute;
    /*background: #ffffff;*/
    /*-webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);*/
    /*box-shadow: 0 2px 10px rgba(0, 0, 0, .2);*/
    /*padding: 20px;*/
}

.updateProgress {
    font-size: 15px;
    background: #5372e4;
    margin: 5px auto;
    color: white;
    text-align: center;
}

.updateProgress,
.updateAndSend {
    font-size: 15px;
    background: #5372e4;
    margin: 5px auto;
    color: white;
    width: 100%;
    display: block;
    text-align: center;
    padding: 10px 0;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}

.updateProgress:hover,
.updateAndSend:hover {
    cursor: pointer;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .4);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .4);
}

.updateProgress.disabled,
.updateAndSend.disabled {
    opacity: .8;
    cursor: not-allowed;
}

.button {
    margin-top: 30px;
    width: 100%;
    min-width: 200px;
    cursor: pointer;
    height: 53px;
    background-color: #5372e4;
    color: #ffffff;
    border: 2px solid #5372e4;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
}


.updateAndSend2.disabled,
.updateAndSend2.disabled:hover {
    background: #4CD964 !important;
    cursor: not-allowed;
    opacity: .8;
}
