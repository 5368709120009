@media only screen and (min-width: 500px) {
    .equivalenceHeading {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
}

@media only screen and (max-width: 500px) {
    div.equivalenceName {
        padding: 6px 0;
    }

    i.hint:before {
        top: 2px;
    }
}

.equivalenceName {
    /*font-size: 14px;*/

    /*line-height: 22px;*/
    padding: 4px 0;
}

/*.animatedRow {*/
/*    opacity: 0;*/
/*    width: 0;*/
/*}*/

/*.animatedRow.show  {*/
/*    !*height: 2em;*!*/
/*    width: 500px;*/
/*    opacity: 1;*/
/*    transition: all 0.4s ease-in;*/
/*}*/

.equivalencePadding .check,
.equivalencePadding .uncheck {
    float: none;
    /*padding: 10px;*/
    /*border-bottom: 1px solid rgba(150, 150, 150, 0.4);*/
    /*-webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);*/
    /*box-shadow: 0 2px 10px rgba(0, 0, 0, .2);*/
}

.logoGuarantees{
    /*width: 600px;*/
    text-align: center;
}

.logo {

    /*width: 240px;*/
    /*opacity: 0;*/
    height: 145px;
    opacity: 0;
    width: auto;

    /*width: 150px;*/
    margin: 15px 30px;

    margin-top: 150px;

    display: inline-block;
    vertical-align: middle;
    transition: opacity .6s linear, height .5s cubic-bezier(.88,.18,.83,.67), margin .5s cubic-bezier(.88,.18,.83,.67);
}

.logo.insurer {
    transition-delay: .6s;
}

.loader {
    margin: 30px auto;
    transition: opacity .6s linear, height .4s cubic-bezier(.88,.18,.83,.67);
    opacity: 0;
}
.loader.show {
    opacity: 1;
}
.loader.minimize {
    opacity: 0;
    height: 0!important;
}
.logo.show.logo-afi-esca,
.logo.logo-afi-esca {
    height: 80px;
}
.logo.logo-afi-esca.minimize {
    height: 55px;
}
.logo.show {
    height: 145px;
    opacity: 1;
    /*margin-top: -10px;*/
    /*padding-bottom: 30px;*/
}

@media screen and (max-width: 768px) {
    .logo {
        margin-top: 60px;
    }
    .logo.wedou {
        display: none;
    }
    .logo.minimize.wedou {
        opacity: 0;
        /*height: 0!important;*/
        margin: 0;
        margin-left: -55px;
    }
    .logo.insurer {
        transition-delay: 0s;
    }
    .logo.minimize {
        height: 60px!important;
        margin: 15px 15px;
    }
}

.logo.minimize {
    height: 90px;
    margin-top: 15px;
    transition-delay: 0s!important;
}

.logo.axa {
    width: 85px;
}

.check {
    color: green;
    padding: 5px;
    float: right;
}

.insurer-card {
    display: flex;
    width: 32%;
    max-width: 430px;
    margin-right: 10px;
    margin-left: 10px;
    padding: 25px 35px 35px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border: 1px none #ebecf0;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 4px 60px 0 rgb(116 134 235 / 7%), 0 4px 26px 0 rgb(0 0 0 / 5%);
}

.icon {
    padding-right:8px;
    padding-left: 8px;
    display: inline-block;
}

.check_voyage {
    color: #15de95;
    border-radius: 15px;
    background-color: #eafcea;
    width: 20px;
}

.uncheck {
    color: red;
    padding: 5px;
    width: 14px;
    float: right;
}

.green {
    color: green;
}
.bold {
    font-weight: bold;
}
.red {
    color: red;
}

.hint:before {
    top: 0;
    margin-left: -16px;
    position: absolute;
}

.footer {
    margin-top: 1rem;
}

.footerText {
    text-align: center;
    padding-top: 1rem;
}

.tableHeading {
    text-align: center;
    /*background: #f8f8f8;*/
    border: 1px solid #f1f1f1;
    background: #f1f1f1;
    border-bottom: none;
    font-size: 17px;
    color: #585858;
    font-weight: 450;
}

.guarantie {
    text-align: left;
    font-family: Montserrat, sans-serif;
    color: #4c4c4c;
    font-size: 16px;
    font-weight: 300;
}

.tableHeading.premium {
    margin-top: 15px;
}
