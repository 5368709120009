.suggestion-item,
.suggestion-item--active {
    cursor: pointer;
    padding: 8px 15px;
}
.suggestion-item--active {
    background: rgba(50,50,50,0.2);
}
.placesInputWrapper {
    width: 100%;
}
.autocomplete-dropdown-container {
    -webkit-box-shadow: 0 2px 10px rgba(0,0,0,.2);
    box-shadow: 0 2px 10px rgba(0,0,0,.2);
    position: absolute;
    background: #ffffff;
    min-width: 300px;
    z-index: 100;
}
.MuiFormLabel-root.MuiInputLabel-formControl {
    padding: 0 4px;
}

.retour_button {
    border: 1px solid black;
}