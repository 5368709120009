.deffered {
    margin-left: 30px;
    font-weight: bold;
}
.resumeRow:hover {
    padding: 10px 0;
    cursor: pointer;
    background: #f1f1f1;
}
.MuiTableCell-root.resumeCell.resumeCell {
    border: none;
    padding: 10px 25px;
    word-break: break-word;
}

.MuiTableCell-root.resumeCell.resumeCell.rightCell {
    border: none;
    padding: 10px 25px;
    font-weight: 600;
    font-size: 16px;
    word-break: break-word;
}
.resumeCell .fa-pencil {
    padding-left: 10px;
}

.resumeLoan {
    display: flex;
    margin-top: 15px;
}

.resumeFrame {
    padding: 15px;
    margin-top: 4vh;
    border-radius: 25px !important;
    background-color: white;
    box-shadow: 0 1px 5px rgb(0 0 0 / 20%) !important;
}

@media screen and (max-width: 400px) {
    .titles {
        text-align: center;
        font-size: 25px;
        font-weight: 600;
        color: #18143b;
    }
}

.titles {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #18143b;
}

#selector {
    overflow: hidden;  /* Hide the element content, while height = 0 */
    height: 0;
    opacity: 0;
    transition: height 0ms 400ms, opacity 400ms 0ms;
}
#selector.visible {
    height: auto; opacity: 1;
    transition: height 1ms 1ms, opacity 600ms 1ms;
}

#selector.hide {
   animation: hide 1s linear;
   animation-fill-mode: forwards;
}

.toggleTable:hover {
    background-color: #E8E8E8;
    cursor: pointer;
}

.resumeEdit {
    margin: auto;
    cursor: pointer;
    text-align: center;
    width: 120px;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 5px;
    padding: 10px;
    background: white;
    display: inline-block;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}
.resumeEdit:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
    background: ghostwhite;
    color: white;
}
.updateForm {
    min-width: 700px;
}
